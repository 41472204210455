export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('name', '点位名称').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('siteCode', '点位编码').setSpan({ canCopy: true }),
        BaseTableColumn.initData('', '安装信息', 120).setKeyValueList([
          { label: '点位位置:', labelWidth: 60, key: 'sitePositionDesc' },
          { label: '雨棚状态:', labelWidth: 60, key: 'canopyStatusDesc' }
        ]),
        BaseTableColumn.initData('communityName', '所属小区').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('propertyCompanyName', '所属\n物业公司').setSpan({ canTooltip: true }),
        BaseTableColumn.initData('area', '所在区域').setKeyValueList([
          { label: '省份：', labelWidth: 50, key: 'provinceName', attr: { canTooltip: true } },
          { label: '城市：', labelWidth: 50, key: 'cityName', attr: { canTooltip: true } }
        ]).setWidth(120),
        BaseTableColumn.initData('deviceCount', '充电桩设备').setWidth(80).setButtons([
          { title: '查看', name: 'check' }
        ], ({ name }, row, index) => {
          console.log(row);
          this.requestDeviceCount(row);
        }),
        BaseTableColumn.initData('createTime', '创建时间').setWidth(150).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setSpan({}).setWidth(70).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:site:status'),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '编辑', tabIndex: 0, name: 'edit', permission: 'system:site:edit' },
          { title: '运维人员信息', tabIndex: 1, name: 'contact', permission: 'system:site:manager:edit' },
          { title: '运营设置', tabIndex: 2, name: 'setting', permission: 'system:site:opration:set' }
        ], ({ tabIndex, name }, row, index) => {
          switch (name) {
          case 'edit':
          case 'contact':
            this.dialog.settingIndex = tabIndex;
            this.requestInfoData(row.id);
            break;
          case 'setting':
            this.dialog.settingIndex = tabIndex;
            this.requestOperationInfo(row.id);
            break;
          }
        })
      ]
    };
  }
};
